import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import web3 from "../web3";
import ava from "../ava";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Input,
  Col,
} from "reactstrap";

class Stake extends React.Component {
  state = {
    ava: null,
    StakeSuccess: null,
    StakeError: null,
    WithdrawSuccess: null,
    WithdrawError: null,
    stakedAmount: 0,
    sender: null,
    refresh: false,
    balance: null,
    loading: null,
  };

  async componentDidMount() {
    const account = await web3.eth.getAccounts();
    this.setState({sender: account[0]});
    // console.log(account);
    let stakedAmount = await ava.methods.stakeOf(this.state.sender).call();
    this.setState({stakedAmount: stakedAmount});
    this.setState({balance: await ava.methods.balanceOf(this.state.sender).call()});
    console.log(this.state.balance);
  }

  StakeAVA = async () => {
    this.setState({loading: true});
    const accounts = await web3.eth.getAccounts();
    if (this.state.ava === null) {
      alert("Please enter AVAR to be staked");
    }

    console.log(accounts[0], this.state.ava, this.state.sender);
    try {
      await ava.methods.createStake(this.state.ava).send({
        from: accounts[0],
      });
      this.setState({ StakeSuccess: true, refresh: true, loading: null });
      window.location.reload(this.state.refresh);
    } catch (e) {
      this.setState({ StakeSuccess: false, StakeError: e, loading: null });
    }
  };

  WithdrawStake = async () => {
    this.setState({loading: true});
    const accounts = await web3.eth.getAccounts();
    try {
      await ava.methods.withdrawStakes().send({
        from: accounts[0],
      });
      this.setState({ WithdrawSuccess: true, loading: null });
      window.location.reload(this.state.refresh);
    } catch (e) {
      this.setState({ WithdrawSuccess: false, WithdrawError: e, loading: null });
    }
  };

  render() {
    let loadMessage;
    if(this.state.loading === true) {
      loadMessage = (<p style={{ color: "white", textAlign: "center", fontWeight: "bold", fontSize: "25px" }}>Loading...</p>);
    }
    let stakeReturned = (this.state.stakedAmount).toString();
    if(stakeReturned > 0) {
      stakeReturned = stakeReturned.substring(0, stakeReturned.length-18)+"."+stakeReturned.substring(stakeReturned.length-18, stakeReturned.length);
    } else {
      stakeReturned = '0.0';
    }
    // console.log(stakeReturned);
    let StakeMessage;
    let WithdrawMessage;
    if (this.state.StakeSuccess === true) {
      StakeMessage = (
        <Col xl="8">
          <Card
            style={{
              backgroundImage:
                "linear-gradient(to right, #ddd6f3 35%, #faaca8 100%)",
              padding: "10px",
              margin: "40px",
              marginLeft: "470px",
            }}
            inverse
          >
            <CardBody style={{ color: "black" }}>
              <CardTitle tag="h5">Success!</CardTitle>
              <CardText style={{ color: "black" }}>
                You have staked {this.state.ava} AVAR!
              </CardText>
            </CardBody>
          </Card>
        </Col>
      );
    } else if (this.state.StakeSuccess === false) {
      StakeMessage = (
        <Col xl="8">
          <Card
            style={{
              backgroundImage:
                "linear-gradient(to right, #ddd6f3 35%, #faaca8 100%)",
              padding: "10px",
              margin: "40px",
              marginLeft: "470px",
            }}
            inverse
          >
            <CardBody style={{ color: "black" }}>
              <CardTitle tag="h5">Error!</CardTitle>
              <CardText style={{ color: "black" }}>
                Error, staking failed. Please try again!
              </CardText>
            </CardBody>
          </Card>
        </Col>
      );
    }
    if (this.state.WithdrawSuccess === true) {
      WithdrawMessage = (
        <Col xl="8">
          <Card
            style={{
              backgroundImage:
                "linear-gradient(to right, #ddd6f3 35%, #faaca8 100%)",
              padding: "10px",
              margin: "40px",
              marginLeft: "470px",
            }}
            inverse
          >
            <CardBody style={{ color: "black" }}>
              <CardTitle tag="h5">Success!</CardTitle>
              <CardText style={{ color: "black" }}>
                You have withdrawn <p style={{ color: "black", fontWeight: "bold" }}>{stakeReturned} AVAR.</p>!
              </CardText>
            </CardBody>
          </Card>
        </Col>
      );
    } else if (this.state.WithdrawSuccess === false) {
      WithdrawMessage = (
        <Col xl="8">
          <Card
            style={{
              backgroundImage:
                "linear-gradient(to right, #ddd6f3 35%, #faaca8 100%)",
              padding: "10px",
              margin: "40px",
              marginLeft: "470px",
            }}
            inverse
          >
            <CardBody style={{ color: "black" }}>
              <CardTitle tag="h5">Error!</CardTitle>
              <CardText style={{ color: "black" }}>
                Error, withdraw failed. Please try again!
              </CardText>
            </CardBody>
          </Card>
        </Col>
      );
  }
    return (
      <div>
      {loadMessage}
        <Header />
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">Stake AVAR</h1>
                </div>
                <div className="breadcrumbs style2">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Stake</li>
                  </ul>
                </div>
                <div>
                  <Col xl="9">
                    <Card           className="stakeclass"

                      inverse
                    >
                      <CardBody style={{ color: "black" }}>
                        <CardTitle tag="h5">Staking AVA</CardTitle>
                        <CardText>
                          {" "}
                          <Input
                            style={{ marginTop: "30px" }}
                            id="ava"
                            name="ava"
                            required
                            placeholder="Enter your stake amount"
                            type="number"
                            onChange={(event) =>
                              this.setState({ ava: event.target.value })
                            }
                          />{" "}
                          <h6 style={{ color: "black" }}>Min. stake amount: 100 AVAR</h6>
                        </CardText>
                        <Button
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #aa076b 30%, #61045f 100%)",
                            width: "75px",
                            height: "40px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "white",
                          }}
                          onClick={() => this.StakeAVA()}
                        >
                          Stake
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  {StakeMessage}
                </div>
                <div>
                  <div className="page-title-heading mg-bt-12">
                    <h1 className="heading text-center"> Your Stakes</h1>
                  </div>
                  <Col xl="9">
                    <Card
                       className="stakeclass"
                      inverse
                    >
                      <CardBody style={{ color: "black" }}>
                        <CardText>
                          <p style={{ color: "black" }}>
                            {this.state.sender} has staked <p style={{ color: "black", fontWeight: "bold" }}>{stakeReturned} AVAR.</p> 
                          </p>
                        </CardText>
                        <Button
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #aa076b 30%, #61045f 100%)",
                            width: "110px",
                            height: "40px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "white",
                            marginTop: "10px",
                          }}
                          onClick={() => this.WithdrawStake()}
                        >
                          Withdraw
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  {WithdrawMessage}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Stake;
