import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
import CardModal from './CardModal'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import imgbg from '../../assets/images/backgroup-secsion/img_bg_page_title.jpg'


const LiveAuction = props => {
    const data = props.data;

    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={imgbg} alt="Axies" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">- ABOUT AVARIDE Token </h2>
                                   <p>AVAR token was founded in April 2022 with the sole purpose of building an entire eco-system for merchants and consumers. </p>
                                   <p>AVAR is the most rapid smart contract platform in the blockchain industry. </p>
                                   <p>AVAR is amazingly fast, low cost and eco-friendly. A decentralised token backed by Binance smart chain
Bep20 platform. A decentralized virtual reality token in which the customers and merchants can
transact reliably, cost-effectively, securely and instantly. </p>
                                    <div className="heading-live-auctions">
                                        <Link to="about" className="sc-button exp style2">EXPLORE MORE</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="tf-section live-auctions">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title pb-20">
                                    The best cryptocurrency to invest in Worldwide
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Swiper>
                                <SwiperSlide>
                                    <div className="swiper-container show-shadow carousel auctions">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="slider-item">
                                                    <div className="sc-card-product">
                                                        <div className="card-media">
                                                            <Link to="#"><img src="assets/icon/1.jpeg" alt="axies" /></Link>

                                                        </div>
                                                        <div className="card-title">
                                                            <h5>AVAR is a decentralised token backed by Binance smart chain Bep20 platform. A decentralized virtual
                                                                reality token in which the customers and merchants can transact reliably, cost-effectively, securely and
                                                                instantly.
                                                                 AVAR is going to launch it’s Utility app for its customers and continuously striving to make AVAR available
                                                                on many utilities.</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>

                        <div className="col-md-4">
                            <Swiper>
                                <SwiperSlide>
                                    <div className="swiper-container show-shadow carousel auctions">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="slider-item">
                                                    <div className="sc-card-product">
                                                        <div className="card-media">
                                                            <Link to="#"><img src="assets/icon/2.jpeg" alt="axies" /></Link>

                                                        </div>
                                                        <div className="card-title">
                                                            <h5  style={{
              textTransform: "None",
            }}>Since AVAR Cannot Be Mined, The Most Popular Way to Get Your Hands On Some is By
                                                                Purchasing it Directly From The Website and tThrough Trust Wallet.
                                                                AVAR also comes with a Peer to peer (P2P) option.
                                                                Trust wallet is global and currently among the most popular exchange of crypto trading
                                                                pairs. Whereas it can also be bought with any currency </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                        <div className="col-md-4">
                            <Swiper>
                                <SwiperSlide>
                                    <div className="swiper-container show-shadow carousel auctions">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="slider-item">
                                                    <div className="sc-card-product">
                                                        <div className="card-media">
                                                            <Link to="#"><img src="assets/icon/3.jpeg" alt="axies" /></Link>

                                                        </div>
                                                        <div className="card-title">
                                                            <h5>AVAR uses a consensus mechanism that is known as delegated proof-of-stake.
                                                                AVAR will run on the Binance smart chain Bep20 platform.
                                                                AVAR uses encryption to verify transactions. This means advanced coding is involved in
                                                                storing and transmitting cryptocurrency data between wallets and to public ledgers.
                                                                In addition, The AVAR token features a wide variety of additional defenses against various
                                                                possible attack vectors</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>

    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LiveAuction;
