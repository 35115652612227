import Web3 from "web3"; //Web3 is a constructor function

let web3;

if (typeof window !== "undefined" && typeof window.web3 !== "undefined") {
  // in the browser and metamask is running
  web3 = new Web3(window.web3.currentProvider);
  web3.currentProvider.enable();
} else {
  // in the server OR user is not running metamask
  web3 = new Web3('https://bsc-dataseed1.binance.org:443');
  web3.currentProvider.enable();
}

export default web3;
